<template>
  <v-row>
    <v-dialog
      v-model="show"
      rounded
      max-width="600px"
      @click:outside="$emit('toggle')"
      @keydown.esc="$emit('toggle')"
    >
      <v-card>
        <v-app-bar color="accent" dense>
          <v-toolbar-title v-if="title">
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon elevation="2" small @click="$emit('toggle')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <slot></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      // dialog: true
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped></style>
