<template>
  <div>
    <v-container>
      <Modal
        :show="showPasswordResetModal"
        @toggle="togglePasswordResetModal"
        :title="$t('userProfile.passwordReset')"
      >
        <PasswordResetForm
          @resetPassword="resetPassword"
          @toggle="togglePasswordResetModal"
        />
      </Modal>
      <v-row justify="center">
        <v-col cols="6">
          <v-form ref="form">
            <v-text-field
              :label="$t('userProfile.firstName')"
              v-model="formData.firstName"
            ></v-text-field>
            <v-text-field
              :label="$t('userProfile.lastName')"
              v-model="formData.lastName"
            ></v-text-field>
            <v-text-field
              :label="$t('userProfile.phone')"
              v-model="formData.phone"
            >
            </v-text-field>
            <v-btn color="success" class="mr-4" @click="save">
              {{ $t('userProfile.save') }}
            </v-btn>
            <v-btn color="info" @click="togglePasswordResetModal">
              {{ $t('userProfile.changePassword') }}
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Modal from './../components/Modal'
import PasswordResetForm from './../components/PasswordResetForm'
import { mapState } from 'vuex'
export default {
  components: {
    Modal,
    PasswordResetForm,
  },
  data() {
    return {
      showPasswordResetModal: false,
      formData: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
      },
    }
  },
  created() {
    this.$store.dispatch('user/getProfile')
  },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
  },
  watch: {
    profile() {
      this.formData = Object.assign({}, this.profile)
    },
  },
  methods: {
    save() {
      this.$store.dispatch('user/updateProfile', this.formData).then(() => {
        this.$store.dispatch('snackbar/addSnackbar', {
          text: `${this.formData.firstName} ${
            this.formData.lastName
          } ${this.$i18n.t('userProfile.changesRegisteredSuccessfully')}.`,
          color: 'success',
        })
      })
    },
    togglePasswordResetModal() {
      this.showPasswordResetModal = !this.showPasswordResetModal
    },
    resetPassword(email) {
      this.$store.dispatch('user/resetPassword', { email })
    },
  },
}
</script>

<style lang="scss" scoped></style>
