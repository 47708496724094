<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title>
      {{ $t('forms.provideEmailAddressForPasswordReset') }}:
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          :label="$t('forms.email')"
          v-model="email"
          type="text"
          prepend-icon="mdi-email"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn class="success" @click="resetPassword">{{
        $t('buttons.reset')
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      email: '',
    }
  },
  methods: {
    resetPassword() {
      this.$emit('resetPassword', this.email)
      // Call reset password
    },
  },
}
</script>

<style lang="scss" scoped></style>
